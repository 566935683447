import React from "react";
import "../css/component/foundation.scss";

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";
import LogoPaper from "../componets/LogoPaper";
import { Grid } from "@mui/material";

const Foundation = () => {
  return (
    <div className="foundation">
      <div className="inner">
        <div className="title">
          <h2>Foundation</h2>
          <p>
            <FormattedMessage id="Foundation_Title01" />
            <br />
            <FormattedMessage id="Foundation_Title02" />
          </p>
        </div>
        <Grid container md={12} xd={12} spacing={0} alignItems="center">
          <LogoPaper>
            <img
              src={`/assets/partner/digitalassetsoasis.jpg`}
              alt="DigitalAssetsOasis"
            />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/blocklogica.png`} alt="Blocklogica" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/foundation/menambtcs.jpg`} alt="menambtcs" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/agss.jpg`} alt="AGSS" />
          </LogoPaper>
        </Grid>
      </div>
    </div>
  );
};

export default Foundation;
