import React, { useEffect, useRef, useState } from "react";
import "../css/component/header.scss";

// 반응형 조건 줄 때 사용했음 / ex) pc에는 있고 m에는 없을 떄
import { useMediaQuery } from "react-responsive";

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

const Header = ({
  navItems,
  onNavItemClick,
  activeNavItem,
  handleLocaleChange,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const HeaderScroll = useRef();

  // --- { 모바일, 테블릿일때 햄버거 버튼을 누르고 메뉴가 나오면 스크롤 이벤트가 안되게}
  const cover = useRef(null);
  const wheelStop = (e) => {
    e.preventDefault();
  };
  // --- }

  // --- { 스크롤 내렸을때 header className에 active를 붙이는 코드}
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let sct = window.scrollY;
      sct > 0
        ? HeaderScroll.current.classList.add("active")
        : HeaderScroll.current.classList.remove("active");
    });

    if (cover.current) {
      cover.current.addEventListener("wheel", wheelStop);
    }

    return () => {
      window.removeEventListener("scroll", () => {
        let sct = window.scrollY;
        sct > 0
          ? HeaderScroll.current?.classList.add("active")
          : HeaderScroll.current?.classList.remove("active");
      });

      // --- { 모바일, 테블릿일때 햄버거 버튼을 누르고 메뉴가 나오면 스크롤 이벤트가 안되게}
      if (cover.current) {
        cover.current.removeEventListener("wheel", wheelStop);
      }
      // --- }
    };
  }, []);

  // --- { 언어 설정 버튼
  const [selectedButton, setSelectedButton] = useState("en-US");
  const handleButtonClick = (button) => {
    setSelectedButton(button); // selectedButton 값 업데이트
    handleLocaleChange(button); // handleLocaleChange 호출
  };
  // --- }

  // --- { 모바일, 햄버거 버튼 토글
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen); // 현재 상태의 반대값으로 변경 (토글 기능)
  };
  // --- }

  // - { 모바일, 메뉴 클릭하면 창이 닫히고 그 부분으로 스크롤 이동
  const handleMenuClick = (navItemId) => {
    onNavItemClick(navItemId);
    setIsOpen(false); // 메뉴 클릭 시 isOpen 상태를 닫힌 상태로 업데이트
  };
  // --- }

  return (
    <header className="header" ref={HeaderScroll}>
      <section className="header_wrap">
        <h1 className="logo">
          <a href="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/logo_w.png"}
              alt="Logo"
            />
          </a>
        </h1>

        <nav className="gnb">
          <ul>
            {navItems.map((navItem) => (
              <li
                key={navItem.id}
                className={activeNavItem === navItem.id ? "active" : ""}
                onClick={() => onNavItemClick(navItem.id)}
              >
                {navItem.label}
              </li>
            ))}
          </ul>
        </nav>

        {/* 모바일 일때 */}
        {isMobile ? (
          <div className="m_lang_hambergur_wrap">
            <div
              className={`mopen ${isOpen ? "active" : ""}`}
              ref={cover}
              onClick={handleClick}
            >
              <button></button>
              {isOpen && (
                <nav className="aisde_menu">
                  <ul>
                    {navItems.map((navItem) => (
                      <li
                        key={navItem.id}
                        className={activeNavItem === navItem.id ? "active" : ""}
                        // 메뉴 클릭하면 창이 닫히고 그 부분으로 스크롤 이동
                        onClick={() => handleMenuClick(navItem.id)}
                      >
                        {navItem.label}
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        ) : null}
      </section>
    </header>
  );
};

export default Header;
