import React, { useRef } from 'react';
import AboutMBTCs from '../pages/AboutMBTCs';
import Ecosystem from '../pages/Ecosystem';
import Foundation from '../pages/Foundation';
import Service from '../pages/Service';
import Partners from '../pages/Partners';
import News from '../pages/News';
import MainVisual from '../pages/MainVisual';
import WorldMap from '../pages/WorldMap';

const Main = ({ componentRefs }) => {
    return (
        <main>
            <section>
                <MainVisual/>
            </section>
            <section ref={componentRefs.AboutMBTCs}>
                <AboutMBTCs/>
            </section>
            <section ref={componentRefs.WorldMap}>
                <WorldMap/>
            </section>
            <section ref={componentRefs.Ecosystem}>
                <Ecosystem/>
            </section>
            <section ref={componentRefs.Foundation}>
                <Foundation/>
            </section>
            <section ref={componentRefs.Service}>
                <Service/>
            </section>
            <section ref={componentRefs.Partners}>
                <Partners/>
            </section>
            <section ref={componentRefs.News}>
                <News/>
            </section>
        </main>
    );
};

export default Main;
