import React, { useEffect, useRef, useState } from 'react';
import Header from './componets/Header';
import Main from './componets/Main';
import Footer from './componets/Footer';
import ToTop from './componets/ToTop';

import { IntlProvider } from "react-intl";
import enUsMsg from "./lang/en-US.json";
import koMsg from "./lang/ko.json";

// 언어의 기본값을 설정하는 것 ---> en-US으로 시작 ---> localeStorage가 잘 가져와지는지 console찍어보기 ---> ko로 바꿔도 안바뀌면 f12눌러서 스토리지 데이터 삭제
// const locale = localStorage.getItem("locale") ?? "en-US";
// console.log(locale);
// const messages = { "en-US": enUsMsg, ko: koMsg }[locale];
// console.log(messages);

const App = () => {
    const defaultLocale = "en-US"; // 기본 언어 설정
    const [locale, setLocale] = useState(defaultLocale);
    const messages = { "en-US": enUsMsg, ko: koMsg }[locale];

    const componentRefs = {
        AboutMBTCs: useRef(null),
        WorldMap: useRef(null),
        Ecosystem: useRef(null),
        Foundation: useRef(null),
        Service: useRef(null),
        Partners: useRef(null),
        News: useRef(null),
        ContactUs: useRef(null),
        // 필요한 만큼 컴포넌트 ref를 추가합니다.
    };

    const navItems = [
        { id: 'AboutMBTCs', label: 'About MBTCs', componentRef: componentRefs.AboutMBTCs },
        { id: 'WorldMap', label: 'Global Ambassador & VIP NFT', componentRef: componentRefs.WorldMap },
        { id: 'Ecosystem', label: 'Ecosystem', componentRef: componentRefs.Ecosystem },
        { id: 'Foundation', label: 'Foundation', componentRef: componentRefs.Foundation },
        { id: 'Service', label: 'Service', componentRef: componentRefs.Service },
        { id: 'Partners', label: 'Partners', componentRef: componentRefs.Partners},
        { id: 'News', label: 'News', componentRef: componentRefs.News},
        // { id: 'ContactUs', label: 'Contact us' },
        // 필요한 다른 네비게이션 항목들을 추가합니다.
    ];

    const [activeNavItem, setActiveNavItem] = useState('AboutMBTCs');

    const scrollToComponent = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleNavItemClick = (componentName) => {
        scrollToComponent(componentRefs[componentName]);
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;

        const activeItem = navItems.find((item, idx) => {
            if (idx === navItems.length - 1) return true;
            return scrollPosition >= item.componentRef.current.offsetTop && scrollPosition
                < navItems[idx + 1].componentRef.current.offsetTop;
        });

        setActiveNavItem(activeItem.id)
    };

    useEffect(() => {
        // 스크롤 이벤트가 발생하기 전에 active 상태 초기화
        setActiveNavItem('fake');

        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트가 언마운트되면 스크롤 이벤트 리스너 해제
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLocaleChange = (newLocale) => {
        setLocale(newLocale);
        localStorage.setItem("locale", newLocale);
    };

    useEffect(() => {
        if (!localStorage.getItem("locale")) {
            localStorage.setItem("locale", defaultLocale);
        }
    }, []);

    return (
        <IntlProvider locale={locale} messages={messages}>
            {/* Intlprovider 써서 전체 적으로 번역 값이 들어가게 젤 크게 싸주자 */}
            {/* 국제화 기능을 사용하려는 최상위 컴포넌트에서 IntlProvider 컴포넌트를 사용해야 합니다. */}

            <Header
                navItems={navItems}
                onNavItemClick={handleNavItemClick}
                activeNavItem={activeNavItem}
                handleLocaleChange={handleLocaleChange}
            />

            <Main
                componentRefs={componentRefs}
            // 필요한 다른 props들을 전달할 수 있습니다.
            />

            <Footer />
            <ToTop />
        </IntlProvider >
    );
};

export default App;
