import React from 'react'
import '../css/component/footer.scss';
import { useMediaQuery } from 'react-responsive';

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const snsData = [
    {
      image: "/assets/youtube.png",
      url: "https://www.youtube.com/@MBTCs-MetaBitcoinSuper",
      alt: "youtube",
    },
    {
      image: "/assets/twitter.png",
      url: "https://twitter.com/MBTCs_NFT",
      alt: "twitter",
    },
    {
      image: "/assets/t.me.png",
      url: "https://t.me/MBTCs_NFT",
      alt: "telegram",
    },
    {
      image: "/assets/instagram.png",
      url: "https://www.instagram.com/mbtcs_NFT",
      alt: "instagram",
    },
    // {
    //   image: "/assets/youtube.png",
    //   url: "https://www.youtube.com/@MBTCs-MetaBitcoinSuper",
    //   alt: "youtube",
    // },
  ];

  return (
    <footer className='footer'>
      <div className='footer_wrap'>
        <div>
          {isMobile ? (
            <div className="inner">
              <figure>
                <img src={process.env.PUBLIC_URL + '/assets/footer_logo.png'} alt="Logo" />
              </figure>
              <ul className="sns">
                {
                  snsData.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img src={process.env.PUBLIC_URL + item.image} alt={item.alt} />
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
              <span>COPYRIGHT &copy 2023 MBTCs.ALL RIGHTS RESERVED.</span>
            </div>
          ) : (
            <div className="inner">
              <figure>
                <img src={process.env.PUBLIC_URL + '/assets/footer_logo.png'} alt="Logo" />
              </figure>
              <span>COPYRIGHT &copy 2023 MBTCs.ALL RIGHTS RESERVED.<br /><br/>
                <a href="mailto:mbtcs.offical@gmail.com"><span>mbtcs.offical@gmail.com</span></a>
              </span>
              <ul className="sns">
                {
                  snsData.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img src={process.env.PUBLIC_URL + item.image} alt={item.alt} />
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
